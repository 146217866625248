import { io } from "socket.io-client";
import Cookies from 'js-cookie';
import ConversationService from "../../services/ConversationService";

const webSocketUrl = process.env.VUE_APP_WEBSOCKET_URL;
const sessionCookieName =  process.env.VUE_APP_SESSION_COOKIE_NAME;

export const namespaced = true;
export const strict = false;

export const state = () => ({
    websocketLoadStatus: 0,
    activeConversations: [],
    callCode: null,
    websocket: null,
    webSocketLoadStatus: 0,
    callRequestLoadStatus: 0,
    chatRequestLoadStatus: 0,
    phoneNumber: null,
    activeChats: {},
    newestChat: null,
    currentChatId: null,
    currentChatObject: {},
    clientId: null,
    loading: false
});

export const mutations = {
    websocketLoadStatus: (state, payload) => { 
        state.websocketLoadStatus = payload; 
    },
    activeConversations: (state, payload) => { 
        state.activeConversations = payload; 
    },
    callCode: (state, payload) => { 
        state.callCode = payload; 
    },
    websocket: (state, websocket) => {
        state.websocket = websocket;
    },
    disconnectWebsocket: (state) => {
        state.websocket.disconnect();
    },
    webSocketLoadStatus: (state, payload) => { 
        state.webSocketLoadStatus = payload; 
    },
    callRequestLoadStatus: (state, payload) => { 
        state.callRequestLoadStatus = payload; 
    },
    chatRequestLoadStatus: (state, payload) => { 
        state.chatRequestLoadStatus = payload; 
    },
    phoneNumber: (state, payload) => { 
        state.phoneNumber = payload; 
    },
    activeChats: (state, payload) => { 
        state.activeChats = payload; 
    },
    newestChat: (state, payload) => { 
        state.newestChat = payload; 
    },
    currentChatId: (state, streamKey) => {
        state.currentChatId = streamKey;
    },
    currentChatObject: (state, object) => {
        state.currentChatObject = object;
    },
    adminLoadStatus: (state, payload) => { 
        state.adminLoadStatus = payload; 
    },
    adminAuthToken: (state, payload) => { 
        state.adminAuthToken = payload; 
    },
    modelListLoadStatus: (state, payload) => { 
        state.modelListLoadStatus = payload; 
    },
    modelList: (state, payload) => { 
        state.modelList = payload; 
    },
    activeModel: (state, payload) => { 
        state.activeModel = payload; 
    },
    activeModelLoadStatus: (state, payload) => { 
        state.activeModelLoadStatus = payload; 
    },
    directLineSecretLoadStatus: (state, payload) => { 
        state.directLineSecretLoadStatus = payload; 
    },
    clientId: (state, id) => {
        state.clientId = id;
    },
    setLoading(state, loading) {
        state.loading = loading;
        console.log('Loading State', loading);
    },
};

export const getters = {
    websocketLoadStatus: (state) => { 
        return state.websocketLoadStatus; 
    },

    activeConversations: (state) => { 
        return state.activeConversations; 
    },

    websocket: (state) => { 
        return state.websocket; 
    },

    callCode: (state) => { 
        return state.callCode; 
    },

    webSocketLoadStatus: (state) => { 
        return state.webSocketLoadStatus; 
    },

    callRequestLoadStatus: (state) => { 
        return state.callRequestLoadStatus; 
    },

    chatRequestLoadStatus: (state) => { 
        return state.chatRequestLoadStatus; 
    },

    phoneNumber: (state) => { 
        return state.phoneNumber; 
    },

    phoneNumberPretty: (state) => {
        if (state.phoneNumber === null)
            return state.phoneNumber

        return `${state.phoneNumber.substr(0,2)} (${state.phoneNumber.substr(2,3)}) ${state.phoneNumber.substr(5,3)}-${state.phoneNumber.substr(8,4)}`
    },

    activeChats: (state) => { 
        return state.activeChats; 
    },

    getActiveChat: (state) => (id) => {
        return state.activeChats[id];
    },

    newestChat: (state) => { 
        return state.newestChat; 
    },

    currentChatId: (state) => {
        return state.currentChatId;
    },

    currentChatObject: (state) => {
        return state.currentChatObject;
    },

    adminLoadStatus: (state) => { 
        return state.adminLoadStatus; 
    },

    adminAuthToken: (state) => { 
        return state.adminAuthToken; 
    },

    modelListLoadStatus: (state) => { 
        return state.modelListLoadStatus; 
    },

    modelList: (state) => { 
        return state.modelList; 
    },

    activeModelLoadStatus: (state) => { 
        return state.activeModelLoadStatus; 
    },

    activeModel: (state) => { 
        return state.activeModel; 
    },

    directLineSecretLoadStatus: (state) => { 
        return state.directLineSecretLoadStatus; 
    },

    clientId: (state) => {
        return state.clientId;
    }
};

export const actions = {
    connectandActivateWebSocket({getters, commit, dispatch}){
        commit('webSocketLoadStatus', 1);
        console.log('WebSocket Load Status', this.state.Chat.webSocketLoadStatus);
        let token = Cookies.get(sessionCookieName) ?? null;
        let websocket = getters['websocket'];
        if(websocket !== null){
            commit('disconnectWebsocket');
            commit('websocket', null);
            console.log('disconnect socket');
        }
        console.log('Token:' + token)
        console.log('Initializing WebSocket Connection')
        const socket = io(webSocketUrl,{
            auth: {
                token: token
            },
            reconnectionDelayMax: 60000,
            reconnectionDelay: 5000,
            transports: [ "websocket" ]
        });
        commit('websocket', socket);

        socket.on("connect",() => {
            commit('webSocketLoadStatus', 2);
            console.log('Websocket Load Status', this.state.Chat.webSocketLoadStatus);
            console.log('Connected', socket.id)
        });

        socket.on("ClientId",(id) => {
            commit('clientId', id);
        });

        socket.on("connect_error",(e) => {
            commit('webSocketLoadStatus', 3);
            console.log('Websocket Load Status', this.state.Chat.webSocketLoadStatus);
            console.log('Connect Error:')
            console.log(e)
        });

        socket.on("disconnect",() => {
            commit('webSocketLoadStatus', 0);
            console.log('Websocket Load Status', this.state.Chat.webSocketLoadStatus);
            commit('adminLoadStatus', 0);
            console.log('Disconnected')
            commit('setLoading', false);
            console.log('Loading State', this.state.Chat.loading);
        });

        socket.onAny((event, ...args) => {
            let payload = args[0] ?? null;
            // console.log(payload);
            switch (event){
                case 'stream-message':
                    dispatch('pushChat', payload);
                    // switch (payload.event.event){
                    //     case "message":
                    //         console.log(`Message (${payload.event.role}): ${payload.event.msg}`)
                    //         dispatch('pushChat', payload);
                    //         break;
                    // }
                    break;
            }

            // switch (event.event ?? null){
            //     case 'callCode':
            //         commit('callCode', event.callCode);
            //         commit('phoneNumber', event.phoneNumber);
            //         break;
            //
            //     case 'chat-new':
            //     case 'chat-update':
            //     case 'chat-typing':
            //     case 'chat-end':
            //         dispatch('pushChat', event);
            //         break;
            //
            //     case 'chat-failed':
            //         dispatch('endChat', event);
            //         commit('callRequestLoadStatus', 3);
            //         commit('chatRequestLoadStatus', 3);
            //         break;
            // }
            // console.log(event, args);
        });
    },
    disconnectWebsocker({getters, commit}){
        commit('disconnectWebsocket');
        let websocket = getters['websocket'];
        console.log("Socket Killed", websocket);
        commit('websocket', null);
        commit('chatRequestLoadStatus', 3);
        console.log('chatRequestLoadStatus', this.state.Chat.chatRequestLoadStatus);
        console.log('Loading State', this.state.Chat.loading);
    },
    pushChat({getters, commit}, payload){
        // let objDiv = document.getElementById("chat-card");
        // objDiv.scrollTop = objDiv.scrollHeight;
        let activeChats = getters['activeChats'];
        let event = payload.event;
        event.timestamp = payload.timestamp ?? null;
        // eslint-disable-next-line
        if(!activeChats.hasOwnProperty(payload.streamKey)){
            activeChats[payload.streamKey] = {};
            activeChats[payload.streamKey].messages = [];
            activeChats[payload.streamKey].systemEvents = [];
            activeChats[payload.streamKey].currentMessage = null;
            //TODO: REMOVE ENTIRE STREAM STORING USE FOR DEBUGGING ONLY
            activeChats[payload.streamKey].stream = [];
            activeChats[payload.streamKey].userTyping = null;
            activeChats[payload.streamKey].botTyping = false;
            activeChats[payload.streamKey].chatEnded = false;
            activeChats[payload.streamKey].ttfbElapsedAverage = 0;
            activeChats[payload.streamKey].messagedToTTFBAverage = 0;
            activeChats[payload.streamKey].messagedToTTFBReceivedAverage = 0;
            activeChats[payload.streamKey].lastUserMessageTime = Date.now();
            commit('newestChat', payload.streamKey);
            commit('currentChatId', payload.streamKey);
        }

        switch (event.event){
            case "TTFB-Start-Stream":
                activeChats[payload.streamKey].ttfbElapsedAverage = (activeChats[payload.streamKey].ttfbElapsedAverage + event?.payload?.elapsed) / 2;
                activeChats[payload.streamKey].messagedToTTFBAverage = (activeChats[payload.streamKey].messagedToTTFBAverage + (Date.now() - activeChats[payload.streamKey].lastUserMessageTime)) / 2;
                activeChats[payload.streamKey].systemEvents.push(event);
                break;

            case "TTFB-Received":
                activeChats[payload.streamKey].messagedToTTFBReceivedAverage = (activeChats[payload.streamKey].messagedToTTFBReceivedAverage + (Date.now() - activeChats[payload.streamKey].lastUserMessageTime)) / 2;
                activeChats[payload.streamKey].systemEvents.push(event);
                break;
            case "message":
                activeChats[payload.streamKey].messages.push(event);
                activeChats[payload.streamKey].systemEvents.push(event);
                switch (event.role) {
                    case "user":
                        activeChats[payload.streamKey].userTyping = null;
                        activeChats[payload.streamKey].lastUserMessageTime = Date.now();
                        break;

                    case "bot":
                        activeChats[payload.streamKey].botTyping = null;
                        break;
                }
                break;
            case "typing":
            case "typing-start":
                switch (event.role) {
                    case "user":
                        activeChats[payload.streamKey].userTyping = event.msg;
                        break;

                    case "bot":
                        activeChats[payload.streamKey].botTyping = event.msg;
                        break;
                }
                break;

            case "typing-end":
                switch (event.role) {
                    case "user":
                        activeChats[payload.streamKey].userTyping = false;
                        break;

                    case "bot":
                        activeChats[payload.streamKey].botTyping = false;
                        break;
                }
                break;

            case "terminate":
                activeChats[payload.streamKey].chatEnded = true;
                activeChats[payload.streamKey].systemEvents.push(event);
                activeChats[payload.streamKey].userTyping = false;
                activeChats[payload.streamKey].botTyping = false;
                break;

            case "typing-delta-start":
            case "typing-delta":
            case "typing-delta-end":
                break;

                default:
                    if (payload?.streamKey) {
                        if (!activeChats[payload.streamKey]) {
                            activeChats[payload.streamKey] = {
                                systemEvents: [],
                                messages: []
                            };
                        }
                        
                        if (!activeChats[payload.streamKey].systemEvents) {
                            activeChats[payload.streamKey].systemEvents = [];
                        }
                        
                        if (event) {
                            activeChats[payload.streamKey].systemEvents.push(event);
                        } else {
                            console.error('Invalid event:', event);
                        }
                    } else {
                        console.error('Invalid streamKey:', payload?.streamKey);
                    }
                    break;
                
                
        }

        activeChats[payload.streamKey].stream.push(event);

        // if(event.event !== 'chat-new' && payload.event !== 'chat-typing' && event.event !== 'chat-end'){
        //     activeChats[event.uuid].messages.push(event);
        // }

        // let callRequestLoadStatus = getters.callRequestLoadStatus;
        // if(callRequestLoadStatus === 1 && event.event !== 'chat-new'){
        //     commit('callRequestLoadStatus', 2);
        // }
        // let chatRequestLoadStatus = getters.chatRequestLoadStatus;
        // if(chatRequestLoadStatus === 1 && event.event !== 'chat-new'){
        //     commit('chatRequestLoadStatus', 2);
        // }
        //
        //
        // activeChats[event.uuid]['typing'] = event.event === 'chat-typing';
        // activeChats[event.uuid]['typingMessage'] = event.event === 'chat-typing' ? event.message : null;
        // activeChats[event.uuid]['typingRole'] = event.event === 'chat-typing' ? event.role : null;
        // activeChats[event.uuid]['typingType'] = event.event === 'chat-typing' ? event.type : null;
        // // eslint-disable-next-line
        // if(activeChats[event.uuid].hasOwnProperty('complete')){
        //     if(!activeChats[event.uuid].complete && event.event === 'chat-end'){
        //         activeChats[event.uuid].complete = true;
        //     }
        // }else{
        //     activeChats[event.uuid]['complete'] = event.event === 'chat-end';
        // }


        commit('activeChats', activeChats);

        //Handle if it is current active chat window...
        let currentChatId = getters['currentChatId'];
        if(payload.streamKey === currentChatId){
            commit('currentChatObject', activeChats[payload.streamKey]);
        }

        // console.log('chat-event', activeChats[payload.streamKey])

        commit('chatRequestLoadStatus', 2);
        // console.log('chatRequestLoadStatus', this.state.Chat.chatRequestLoadStatus)
    },
    closeChat({getters, commit}, uuid){
        let activeChats = getters.activeChats;
        // eslint-disable-next-line
        if(activeChats.hasOwnProperty(uuid)){
            delete activeChats[uuid];
        }
        commit('activeChats', activeChats);
    },
    endChatRequest({getters}, uuid){
        let websocket = getters['websocket'];

        websocket.emit('chat-end-request',{
            streamKey: uuid
        },(response) => {
            console.log(response);
        });
    },
    endCurrentChatRequest({getters}){
        let websocket = getters['websocket'];
        let currentChatId = getters['currentChatId'];

        websocket.emit('chat-end-request',{
            streamKey: currentChatId
        },(response) => {
            console.log(response);
        });
    },
    newChatRequest({getters, commit}, payload){
        let websocket = getters['websocket'];
        commit('chatRequestLoadStatus', 1);
        console.log('chatRequestLoadStatus', this.state.Chat.chatRequestLoadStatus);
        console.log(websocket, payload);
        websocket.emit('chat-new-request', payload,
            (response) => {
                console.log(response);
            });
    },
    newCallRequest({getters,commit}, payload){
        payload.ClientId = getters['clientId'];
        ConversationService.requestOutboundCall(payload).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });
        commit('chatRequestLoadStatus', 1);
        console.log('chatRequestLoadStatus', this.state.Chat.chatRequestLoadStatus);
        commit('callRequestLoadStatus', 1);
        console.log('callRequestLoadStatus', this.state.Chat.callRequestLoadStatus);
        commit('setLoading', true);
        console.log('Loading State', this.state.Chat.loading);
        setTimeout(() => {
            commit('setLoading', false);
            console.log('Loading State', this.state.Chat.loading);
          }, 5000);
        return payload;
    },
    chatSendMessage({getters, commit}, payload){
        let websocket = getters['websocket'];
        let currentChatId = getters['currentChatId'];
        let currentChatObject = getters['currentChatObject'];

        if(currentChatObject.currentMessage !== null &&
            currentChatObject.currentMessage !== undefined &&
            currentChatObject.currentMessage !== ''){
            websocket.emit('chat-send-message',{
                streamKey: currentChatId,
                message: currentChatObject.currentMessage,
                options: payload
            },(response) => {
                console.log(response);
                currentChatObject = getters['currentChatObject'];
                currentChatObject.currentMessage = null;
                commit('currentChatObject', currentChatObject);
            });
        }

    },
    changeActiveChat({getters, commit}, streamKey){
        let currentChatId = streamKey;
        let activeChats = getters['activeChats'];
        let currentChatObject = activeChats[currentChatId];
        commit('currentChatId', currentChatId);
        commit('currentChatObject', currentChatObject);
    },
    chatTextEnd({getters}, payload){
        let websocket = getters['websocket'];
        websocket.emit({
            event: 'chat-text-end',
            uuid: payload.uuid,
        });
    },

};