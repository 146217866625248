import Vuex from 'vuex';

import * as auth from "@/store/modules/Auth";
import * as user from "@/store/modules/User";
import * as role from "@/store/modules/Role";
import * as Instance from "@/store/modules/Instance";
import * as Action from "@/store/modules/Action";
import * as Account from "@/store/modules/Account";
import * as Conversations from "@/store/modules/Conversations";
import * as Chat from "@/store/modules/Chat";
import * as Contact from "@/store/modules/Contact";
import * as Reporting from "@/store/modules/Reporting";
import * as Feedback from "@/store/modules/Feedback";
import * as AccountSettings from "@/store/modules/AccountSettings";
import * as agent from "@/store/modules/Agent";
import * as AgentInstructions from "@/store/modules/AgentInstructions";
import * as tool from "@/store/modules/Tool";
import * as bot from "@/store/modules/Bot";
import * as llm from "@/store/modules/LLM";


export default new Vuex.Store({
    strict: false,

    modules: {
        auth,
        user,
        role,
        Instance,
        Action,
        Account,
        Conversations,
        Chat,
        Feedback,
        Contact,
        Reporting,
        AccountSettings,
        agent,
        AgentInstructions,
        tool,
        bot,
        llm
    }
});